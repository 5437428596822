<template>
  <div class="login-wrapper">
    <div class="card login-form">
      <div class="card-body py-5 px-4">
        <div class="logo mb-5">
          <img src="../../assets/logo.svg" alt="" />
        </div>
        <form methods="POST" @submit="checkForm">
          <b-alert
            :show="!!errors.length"
            dismissible
            variant="warning"
            @dismissed="dismissCountDown = 0"
            @dismiss-count-down="10"
          >
            <p>Verificar o(s) seguinte(s) campo(s)</p>
            <ul>
              <li v-for="error in errors">{{ error }}</li>
            </ul>
          </b-alert>

          <div class="form-group">
            <label for="email">E-mail</label>
            <input
              id="email"
              type="email"
              class="form-control"
              placeholder="E-mail de acesso"
              v-model="form.email"
              aria-describedby="emailHelp"
            />
          </div>
          <div class="form-group">
            <label for="password">Senha</label>
            <input
              id="password"
              type="password"
              placeholder="Sua senha"
              v-model="form.password"
              class="form-control"
            />
          </div>
          <div class="form-group form-check d-flex mb-4">
            <router-link class="ml-auto" to="/auth/forgot-password">
              <small> Esqueci minha senha </small>
            </router-link>
          </div>
          <button
            v-show="!doLoging"
            type="submit"
            id="submit"
            class="btn btn-primary btn-block mb-3"
          >
            Entrar
          </button>
          <b-button
            v-show="doLoging"
            class="btn btn-primary btn-block mb-3"
            disabled
          >
            <b-spinner small type="grow"></b-spinner>
            Loading...
          </b-button>
        </form>

        <!-- <router-link
          class="btn btn-block"
          to="/auth/signup"
        >
        Cadastre-se
        </router-link> -->
      </div>
    </div>
  </div>
</template>

.<style lang="scss" scoped>
input {
  width: 100%;
  height: 40px;
  border: 1px solid #929a9c;
  box-sizing: border-box;
  border-radius: 5px;
}

small {
  margin-right: 4px;
}
.login-wrapper {
  display: flex;
  margin: 0 12%;
  height: 100%;
  width: 350px;
  @media screen and (max-width: 720px) {
    width: 90vw;
    margin: 0 4%;
  }
}

.login-form {
  width: 100%;
  margin: auto;
}

.card {
  border: 1px solid #8a8a8a;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 428px;
}
.logo {
  width: 80%;
  margin: 0 auto;

  > img {
    width: 100%;
  }
}
</style>

<script>
import firebase from "firebase";
import axios from "axios";

export default {
  data() {
    return {
      errors: [],
      form: {},
      doLoging: false,
    };
  },
  methods: {
    checkForm: function (e) {
      e.preventDefault();
      this.doLoging = true;

      if (this.form.email && this.form.password) {
        // return true;
      }

      this.errors = [];

      if (!this.form.email) {
        this.errors.push("Campo E-mail é obrigatório.");
        this.doLoging = false;
      }

      if (!this.form.password) {
        this.errors.push("Campo Senha é obrigatório.");
        this.doLoging = false;
      }

      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(async (data) => {
          this.$analytics.setUserProperties({ email: this.form.email });
          this.$analytics.setUserId(this.form.email);
          this.$analytics.logEvent("login", {
            email: this.form.email,
          });

          this.$session.start();
          this.$session.set("email", this.form.email);
          this.$session.set("userId", data.user.uid);
          this.$session.set(
            "token",
            await firebase.auth().currentUser.getIdToken()
          );
          this.$router.push("/hp");
        })
        .catch((error) => {
          this.$analytics.logEvent("login", {
            event_category: "login",
            event_label: "error",
            value: error.message,
          });

          this.errors.push(error.message);
          this.doLoging = false;
        });
    },
    login() {
      console.log(this.form);
    },
  },
};
</script>
